// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import '../node_modules/@fortawesome/fontawesome-free/js/brands';
import '../node_modules/@fortawesome/fontawesome-free/js/solid';
import '../node_modules/@fortawesome/fontawesome-free/js/fontawesome';
import Popper from "popper.js";
import SmoothScroll from "smooth-scroll";
import Flickity from "flickity";
import AOS from "aos";
window.jQuery = $;
window.$ = $;
require("bootstrap");

import { jarallax, jarallaxVideo } from 'jarallax';

jarallaxVideo();
jarallax(document.querySelectorAll('.jarallax'), {
  speed: 0.2
});

var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 900
});

$(window).on("scroll", function() {
  if($(window).scrollTop()) {
    $('nav').addClass('bg-primary');
  }
  else {
    $('nav').removeClass('bg-primary');
  }
});

AOS.init();
